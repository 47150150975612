import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { AppContext } from './contextes/AppContext';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import Rebase from 're-base'
import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import f1 from "./assets/Footer.svg";
import f2 from "./assets/Footer2.svg";
import "modern-normalize";
import './App.css';

import {firebaseConfig} from './config/firebase'
const app = firebase.initializeApp(firebaseConfig);
const db = Rebase.createClass(firebase.firestore(app))
const store = firebase.storage(app)
console.log(db)
interface AppState {
  logged?: boolean
}

export class App extends React.Component<{}, AppState> {
  state = {
    logged: false
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({logged: !!user})
    })
  }


  render() {
    return (
      <AppContext.Provider value={{
        logged: this.state.logged,
        db,
        store
      }}>
        <Router>
          <Header logged={this.state.logged} />
          <main>
            <Route path="/" exact render={(props) => <Home {...props} />} />
            <Route path="/login" exact render={(props) => <Login {...props} />}/>
            <Route path="/signup" exact component={Register} />
          </main>
          <img src={f2} className="blame" alt=""/>
          <img src={f1} className="blame2" alt=""/>
          <Footer />
        </Router>
      </AppContext.Provider>
    )
  }
}

export default App
