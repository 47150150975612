import { createContext } from "react";
import firebase from 'firebase'

interface AppContext {
  logged: boolean
  db?: any
  store?: any
}

export const AppContext = createContext<AppContext>({
  logged: false,
})
