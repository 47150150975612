import React, {useState} from 'react'
import {Formik, Field, Form, FormikActions} from 'formik'
import {Redirect, NavLink} from 'react-router-dom'
import * as firebase from 'firebase/app';
import 'firebase/auth'
import './page.css'

export const Login: React.FC<any> = (props:any) => {
  const [logged, setLogged] = useState(false)

  const login = async ({email, password}: LoginInput, actions: FormikActions<LoginInput>) => {
    actions.setSubmitting(true)
    const response = await firebase.auth().signInWithEmailAndPassword(email, password)
    actions.setSubmitting(false)
    if (response) setLogged(true)
  }

  const googleSignin = async () => {
    const response = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
    if (response) {
      setLogged(true)
    }
  }

  return (<>
  {logged && <Redirect to="/" />}
  {!logged && (
    <section className="loginWrapper">
      <Formik initialValues={{email: '', password: ''}} onSubmit={login}>
        {({isSubmitting, isValid}) => {
          return (
          <Form className="formWrapper formLogin">
            <h1>Accedi</h1>
            <div className="socialLogin">
              <button type="button" onClick={googleSignin} className="googleSignin">
                Accedi con Google
              </button>
            </div>
            <div className="fieldGroup">
              <Field className="formField" name="email" placeholder="email"/>
              <Field className="formField" name="password" type="password" placeholder="password"/>
            </div>
            <button type="submit" disabled={!isValid || isSubmitting} className="loginSubmit">
              Accedi
            </button>
            <div>
              <NavLink to="/register"> Non sei registrato? Registrati </NavLink>
            </div>
          </Form>
        )}}
      </Formik>
    </section>
  )}
  </>)
}


/*

    <section>
    Login
    <Formik
      initialValues={{password: '', email:'' }}
      onSubmit= {login}
        render={props => (
          <form onSubmit={props.handleSubmit} style={{marginTop:'200px'}}>
            <label>
            Email:
            <input
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
              name="email"
            />
            </label>
            {props.errors.email && <div id="feedback">{props.errors.email}</div>}
            <label>
            Password:
            <input
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.password}
              name="password"
            />
            </label>
            {props.errors.password && <div id="feedback">{props.errors.password}</div>}
            <button type="submit">Login</button>
            <button type="button" onClick={googleSignin}>Google</button>
          </form>
        )}
        />
    </section>

  )}



*/
