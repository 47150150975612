import React, {useLayoutEffect, useRef, useEffect} from 'react'
import { NavLink } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import logo from "../../assets/Logo.svg";
import './Header.css'

interface Props {
  logged: boolean
}

export const Header: React.FC<any> = (props: Props) => {

  const headerNode = useRef<HTMLElement>(null)
  useLayoutEffect(() => {
    const cssVar = getComputedStyle(document.documentElement).getPropertyValue('--header-height')
    if (headerNode.current && `${headerNode.current.clientHeight}px` !== cssVar) {
      document.documentElement.style.setProperty('--header-height', `${headerNode.current.clientHeight}px`)
    }
  })
  const avatar = firebase.auth().currentUser ? firebase.auth().currentUser.photoURL : ''
  const logout = (event) => firebase.auth().signOut()
  return (
    <header className='header' ref={headerNode}>
      <div className="headerWrapper">
        <div className="logo">
          <img src={logo} alt=""/>
        </div>
        <div className="navigation">
          <nav className="pageNavigation">
            <NavLink className="navItem" activeClassName="activeLink" to="/">
              <div className="navLinkText">
                Home
              </div>
            </NavLink>
            <NavLink className="navItem" activeClassName="activeLink" to="/about">
              <div className="navLinkText">
                Chi Siamo
              </div>
            </NavLink>
            <NavLink className="navItem" activeClassName="activeLink" to="/funzionamento">
              <div className="navLinkText">
                Come Funziona
              </div>
            </NavLink>
          </nav>
          <nav className="userNavigation">
          {!props.logged && (
            <>
              <NavLink className="headerButton signup" to={'signup'} > Signup </NavLink>
              <NavLink className="headerButton login" to={'login'} > Login </NavLink>
            </>
          )}
          {props.logged && (
            <>
              <div className="userName">
                <div className="avatar">
                  <img src={avatar} alt=""/>
                </div>
                {firebase.auth().currentUser.displayName}
              </div>
              <div className="headerButton logged" onClick={logout}>Logout</div>
            </>
          )}
          </nav>
        </div>
      </div>
    </header>
  )
}
