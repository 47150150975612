import React, { Component } from 'react'
import { AppContext } from '../contextes/AppContext';
import './page.css'
export class Home extends Component {
  state = {
    loading: true,
    ads: undefined
  }

  static contextType = AppContext;

  async componentDidMount() {
    const p = this.context.db.bindCollection('fakeAdRent', {
      context: this,
      state: 'ads',
      then(data) {
        this.setState({loading: false})
      },
    })
  }

  render() {
    console.log(this.context.store)
    return <>
    <div className="page home">
      <div className="hero">
        <div className="strip">
          <h1>Il buon vicinato a portata di click</h1>
          <div className="search-wrapper">
            <input type="text" placeholder="Cosa stai cercando ?" />
            <select name="" id="">
              <option value="">Hobby e Tempo Libero</option>
              <option value="">Noleggio e Prestito</option>
              <option value="">Viaggi e Spostamenti</option>
              <option value="">Baratto e Bacheca</option>
            </select>
          </div>
        </div>
      </div>
      <section className="page-pad feeds-wrapper">
        <h1>Ultimi Annunci</h1>
        <div className="feeds">
          {this.state.ads && this.state.ads.map(ad => (<div key={ad.sku} className="ad">
            <img src={ad.imgUrl} alt=""/>
            <div className="infos">
              <h2>{ad.title}</h2>
              <div className='price'>{ad.price}€ / {ad.pricingTime === 'H' && 'Orari'}</div>
              <div>
                {[1,2,3,4,5].map(s => s <= ad.feedback ? '★' : '☆')}
              </div>
              <div>Distanza: {ad.distance} Km</div>
              <div className='desctiprion'>{ad.description}</div>
              <div>Utente: {ad.author}</div>
            </div>
          </div>))}
       </div>
      </section>

      <section className="page-pad about">
        <h1>Che cos'è Helping4</h1>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident, blanditiis quibusdam nobis ipsum facilis molestias ratione nam impedit soluta nesciunt, dignissimos rerum autem repellendus reprehenderit ad sint, rem odit? Nostrum!</p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident, blanditiis quibusdam nobis ipsum facilis molestias ratione nam impedit soluta nesciunt, dignissimos rerum autem repellendus reprehenderit ad sint, rem odit? Nostrum!</p>
      </section>

      <section className="video-strip">
        <div className="left">
          <h1>Guarda il video</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque expedita in nostrum, fugit voluptatem laudantium, tempora enim unde impedit, laboriosam numquam non nam soluta eligendi eos ut dolorum quibusdam distinctio?</p>
        </div>
        <div className="right">
          <iframe className="videoElement" width="560" height="315" src="https://firebasestorage.googleapis.com/v0/b/help4-4fc63.appspot.com/o/video%2FHELPING%204%20PIATTAFORMA%20VERSION%20.mp4?alt=media" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
        </div>
      </section>

      <section className="page-pad services-wrapper">
        <h1>I Nostri Servizi</h1>
        <div className="services">
          <div className="service">
            <div className="icon"></div>
            <div className="title"></div>
          </div>
          <div className="service">
            <div className="icon"></div>
            <div className="title"></div>
          </div>
          <div className="service">
            <div className="icon"></div>
            <div className="title"></div>
          </div>
          <div className="service">
            <div className="icon"></div>
            <div className="title"></div>
          </div>
        </div>

      </section>

      <section className="page-pad values-wrapper">
        <h1>I Nostri Valori</h1>
        <div className="list">
          <div className="item">
            <img src="" alt="" />
            <h4></h4>
            <p></p>
          </div>
          <div className="item">
            <img src="" alt="" />
            <h4></h4>
            <p></p>
          </div>
          <div className="item">
            <img src="" alt="" />
            <h4></h4>
            <p></p>
          </div>
          <div className="item">
            <img src="" alt="" />
            <h4></h4>
            <p></p>
          </div>
        </div>
      </section>
      <section className="page-pad support">

      </section>
    </div>
  </>
  }
}
