import React from 'react'
import {Formik} from 'formik'

export const Register: React.FC = () => {
  const register = async(value: any) => {
    // const register = await Firebase.createUser(value.email, value.password)
    console.log(register)
  }

  return (
    <section>
      Signup
      <Formik
          initialValues={{password: '', email:'' }}
          onSubmit= {(value)=>{register(value)}}
          render={props => (
            <form onSubmit={props.handleSubmit} style={{marginTop:'200px'}}>
              <label>
               Email:
              <input
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email}
                name="email"
              />
              </label>
              {props.errors.email && <div id="feedback">{props.errors.email}</div>}
              <label>
              Password:
              <input
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.password}
                name="password"
              />
              </label>
              {props.errors.password && <div id="feedback">{props.errors.password}</div>}
              <button type="submit">Registrati</button>
            </form>
          )}
          />
    </section>
  )
}
