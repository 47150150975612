import React from 'react'
import logo from "../../assets/Logo.svg";
import './Footer.css';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footerTopbar">
        <div className="footerLogo">
          <img src={logo} alt="" />
          <div className="footerLogoText">
            <h1>Helping4</h1>
            <span>Conosci... Condividi... Risparmia</span>
          </div>
        </div>

        <div className="footerCategories">
          <ul>
            <li><a href="">Hobby e Tempo Libero</a></li>
            <li><a href="">Noleggio e Prestito</a></li>
            <li><a href="">Viaggi e Spostamenti</a></li>
            <li><a href="">Baratto e Bacheca</a></li>
          </ul>
        </div>

        <div className="footerContacts">
          <ul>
            <li><a href="">Diventa nostro Partner</a></li>
            <li><a href="">Lavora con noi</a></li>
            <li><a href="">Dicono di noi</a></li>
            <li><a href="">Contatti</a></li>
          </ul>
        </div>

      </div>

      <div className="footerBottombar">
        <div className="footerInfoCompany">
          Copyright © 2019 - Helping4 - P. IVA 08365140154
        </div>

        <div className="footerInfoLinks">
          <ul>
            <li>
              <a href="">Privacy Policy</a>
            </li>
            <li>
              <a href="">Policy Utilizzo</a>
            </li>
            <li>
              <a href="">Informativa Cookies</a>
            </li>
          </ul>
        </div>

        <div className="footerSocials">
          Seguici su:
          <div>
            <span>F</span>
            <span>T</span>
            <span>I</span>
          </div>
        </div>
      </div>
    </footer>
  )
}